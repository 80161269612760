<template>
  <div style="width: 100%;">
    <v-container fluid>
      <v-row>
        <v-col class='d-flex flex-row align-center'>
          <v-btn text class="mx-2" :to="'/'+this.pluralLower">
            <v-icon dark>mdi-arrow-left</v-icon>
          </v-btn>
          <h1>{{this.singular}}: {{this.data.name}}</h1>
          <v-progress-circular
            indeterminate
            color="green"
            v-if="loader"
            style="margin-left: 10px;"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-row>
            <v-col>
              <v-btn v-if="isAllowed('location','u')" @click="isEditable = !isEditable">
                <span v-if="this.isEditable">Cancel</span>
                <span v-else>Edit</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-form :disabled="!isEditable">
            <v-row>
              <v-col>
                <v-text-field
                    label="Name"
                    outlined
                    v-model="data.name"
                />
                <v-checkbox v-if="data.parent===null" :persistent-hint="data.metadata.isManaged===null" hint="This value is unsaved." outlined label="Is Managed?" v-model="data.metadata.isManaged"/>
                <v-checkbox v-if="data.parent===null" :persistent-hint="data.metadata.isWarehouse===null" hint="This value is unsaved." outlined label="Is A Warehouse?" v-model="data.metadata.isWarehouse"/>
                <v-checkbox v-if="data.parent===null" :persistent-hint="data.vatEnabled===null" hint="This value is unsaved." outlined label="Is VAT Enabled for this Branch?" v-model="data.vatEnabled"/>
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-col v-if="isEditable">
              <v-btn @click="update">Save</v-btn>
              <v-btn v-if="isAllowed('location','d')" style="margin-left: 10px;" class="error" @click="deleteData">Delete</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h2>Add Child Location</h2>
              <v-text-field outlined label="Child Name" v-model="child.name"/>
              <v-btn @click="addChildLocation" :disabled="!child.name">Add</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h2>Override Parent Location</h2>
              <v-autocomplete :items="locations" :item-text="x => x.id+' | Name: '+x.name" item-value="id" outlined label="Parent Location" v-model="parentId"/>
              <v-btn @click="overrideParentLocation" :disabled="!parentId || parentId===data.parent">Override</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="data.parent==null">
            <v-col>
              <h2>Branch Information (Used for Invoices/Receipts) [Location.metadata.branchData]</h2>
              <v-text-field outlined label="Name [name]" v-model="data.metadata.branchData.name"/>
              <v-textarea outlined label="Address [address]" v-model="data.metadata.branchData.address"/>
              <v-text-field outlined label="Phone [phone]" v-model="data.metadata.branchData.phone"/>
              <v-text-field outlined label="Email [email]" v-model="data.metadata.branchData.email"/>
              <v-text-field outlined label="Tax Reg. No. [taxReg]" v-model="data.metadata.branchData.taxReg"/>
              <v-textarea outlined label="Other [other]" v-model="data.metadata.branchData.other"/>
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-container>
            <v-row>
              <v-col class="d-flex flex-row align-center">
                <h1>Child {{plural}}</h1>
                <v-progress-circular
                    indeterminate
                    color="green"
                    v-if="loader"
                    style="margin-left: 10px;"
                ></v-progress-circular>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-data-table
                    :headers="headers"
                    :items="data.childLocations"
                    :items-per-page="-1"
                    class="elevation-1"
                    @click:row="rowClick"
                >
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import axios from 'axios';
  import {mapGetters, mapMutations} from "vuex";
  export default {
    data () {
      return {
        deleteDialog: false,
        deleteConfirmed: true,
        loader: true,

        snackObj: {
          state: false,
          color: '',
          text: ''
        },

        headers: [
          {
            text: 'ID',
            align: 'start',
            sortable: true,
            value: 'id',
          },
          {
            text: 'Name',
            value: 'name',
          },
          {
            text: 'Children IDs',
            value: 'children',
          }
        ],

        max25chars: v => v.length <= 25 || 'Input too long!',
        editMode: false,
        data: {
          metadata: {
            isManaged: null,
            isWarehouse: null,
            branchData: {}
          }
        },
        child: {},
        parentId: null,

        locations: [],

        singular: "Location",
        singularLower: "location",
        plural: "Locations",
        pluralLower: "locations",
        isEditable: false,
      }
    },
    async mounted(){
      await this.getAllData();
    },
    computed: {
      ...mapGetters(['getEndpoint', "isAllowed", 'getBranchData', 'getBranch'])
    },
    methods: {
      ...mapMutations(['setBranches', 'setManaged']),
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      rowClick(row){
        this.$router.push({ path: `/${this.pluralLower}/view/${row.id}`})
      },
      async update(){
        try {
          this.loader = true;
          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/${this.$route.params.id}`, this.data)
          if(res.data.error) throw res.data.error

          let locations = await axios.get(`${this.getEndpoint}/api/locations/branches`)
          if(locations.data.error) console.log("APP.JS❌ Location Data Not Fetched.")
          else this.setBranches(locations.data.data);
          this.setManaged(!!this.getBranchData(this.getBranch)?.metadata.isManaged)

          this.snack(`${this.singular} info Updated 🎉`, "success");
          this.isEditable = false;
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.loader = false;
        }
      },
      async overrideParentLocation(){
        try {
          this.loader = true;
          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/overrideParent/${this.$route.params.id}`, this.parentId)
          if(res.data.error) throw res.data.error

          this.data.parent = this.parentId;
          this.parentId = null;
          this.snack(`${this.singular} Parent Updated 🎉`, "success");
          this.isEditable = false;
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.loader = false;
        }
      },
      async deleteData(){
        try {
          this.loader = true
          let res = await axios.delete(`${this.getEndpoint}/api/${this.pluralLower}/${this.$route.params.id}`)
          if(res.data.error) throw res.data.error

          this.snack(this.singular+" Deleted 🎉", "success");
          await this.$router.go(-1)
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.loader = false
        }
      },
      async getAllData(){
        try{
          let res = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}/${this.$route.params.id}`)
          if(res.data.error) throw res.data.error

          let locations = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}`)
          if(locations.data.error) throw locations.data.error

          this.locations = locations.data.data;

          if(!res.data.data.metadata){
            res.data.data.metadata = {isManaged:null}
          }
          if(!res.data.data.metadata.branchData){
            res.data.data.metadata = {
              ...res.data.data.metadata,
              branchData: {}
            }
          }

          this.data = res.data.data

          this.child = {};
          this.child.parent=this.data.id;
          this.parentId = this.data.parent;
          this.locations.splice(this.locations.findIndex(x => x.id===this.data.id), 1);
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
        finally {
          this.loader = false;
        }
      },
      async addChildLocation(){
        try {
          this.loader = true;
          let res = await axios.post(`${this.getEndpoint}/api/${this.pluralLower}`, this.child)
          if(res.data.error) throw res.data.error

          this.snack(`Child ${this.singular} Created 🎉`, "success");
          this.data={};
          await this.getAllData();
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
      },
    }
  }
</script>
