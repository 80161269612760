<template>
  <div class="" style="width: 100%;">
    <v-container fluid>
      <v-row>
        <v-col class='col-md-8 d-flex flex-row align-center'>
          <v-btn text class="mx-2" @click="()=>{$router.go(-1)}">
            <v-icon dark>mdi-arrow-left</v-icon>
          </v-btn>
          <h1 v-if="serialDialog.data">Manage Dispatches for Order #{{serialDialog.data.id}}</h1>
          <confirmedActionButton
              class="mx-2"
              color="info"
              buttonText=""
              fab
              xsmall
              :loading="createDispatchLoading"
              @cb="createNewDispatch(serialDialog.data.id)"
              fabIcon="mdi-plus"
          />
          <v-btn color="info" @click="$router.push(`/orders/view/${serialDialog.data.id}`)">Go to Order<v-icon>mdi-arrow-right</v-icon></v-btn>
          <v-progress-circular
              indeterminate
              color="green"
              v-if="serialDialog.isSyncing"
              class="ml-2"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-row v-if="!serialDialog.data?.deliveryInfo?.dispatches || serialDialog.data.deliveryInfo.dispatches?.length===0">
        <v-col>
          <h3>This order currently has no dispatches.</h3>
        </v-col>
      </v-row>
      <v-row v-if="serialDialog.data">
        <v-col cols="2">
          <span v-if="serialDialog.data.deliveryInfo.dispatches && serialDialog.data.deliveryInfo.dispatches.length > 0" class="d-flex flex-column" style="max-height: 80vh; overflow-y: scroll">
            <v-card class="mb-2" outlined v-for="(dispatch,index) in serialDialog.data.deliveryInfo.dispatches" :key="index" @click="clickDispatch(dispatch.id)">
              <v-card-text>
                <v-chip small :color="utils.getDispatchStatusColor(dispatch.status)">{{(![0, -1, 5, -2].includes(dispatch.status) && dispatch.scheduledOutAt && !(dispatch.scheduledOutAt >= today))?'Delayed - ':''}}{{ utils.parseDispatchStatus(dispatch.status) }}</v-chip>
                <h4>DISPATCH #{{serialDialog.data.id}}-{{ dispatch.id }}</h4>
                <span class="d-flex flex-column">
                  <span>Scheduled For: {{ dispatch.scheduledOutAt?dispatch.scheduledOutAt:'No Date Set' }}</span>
                  <span>Driver: {{ dispatch.driver || 'No Driver Set' }}</span>
                </span>
              </v-card-text>
            </v-card>
          </span>
        </v-col>
        <v-col>
          <v-card outlined v-if="serialDialog.activeArea.ix || serialDialog.activeArea.ix === 0">
            <v-card-text>
              <span class="d-flex flex-row align-center mb-4">
                <h2>DISPATCH #{{serialDialog.data.id}}-{{ serialDialog.activeArea.id }}</h2>
                <v-btn class="mx-2" :disabled="serialDialog.activeArea.status===-1 || serialDialog.activeArea.status===5" small color="success" @click="saveDispatch(serialDialog.activeArea.id)">Save</v-btn>
                <dynamicButtonContainer v-if="serialDialog.activeArea.status!==-1" :containerId="`${getGlobalValue('VEC_PRINT_SYSTEM_CONTAINER_DISPATCH_DIALOG')}`" :data="{status: serialDialog.activeArea.status, id1: serialDialog.data.id, id2: serialDialog.activeArea.id}"/>
                <v-select style="max-width: 200px;" dense :disabled="changeDispatchStatusRules" hide-details label="Status" item-text="text" item-value="value" outlined v-model="tempStatus" :items="dispatchStatuses.filter(x => (dispatchStatuses.find(y => y.value===serialDialog.activeArea.status)?.allowedNext||[]).includes(x.value))"/>
              </span>
              <v-row>
                <v-col cols="8">
                  <v-form :disabled="serialDialog.activeArea.status===-1 || serialDialog.activeArea.status===5">
                    <v-card outlined>
                      <v-card-text outlined class="d-flex flex-row">
                      <span class="mr-4 d-flex flex-column align-start justify-start">
                        <v-date-picker :disabled="serialDialog.activeArea.status===-1 || serialDialog.activeArea.status===5" dense label="Scheduled For" v-model="serialDialog.activeArea.scheduledOutAt"/>
                      </span>
                        <v-row>
                          <v-col>
                            <h3 class="mb-3">Driver Details</h3>
                            <v-text-field label="Driver" outlined dense hide-details class="mb-1" v-model="serialDialog.activeArea.driver"/>
                            <v-textarea class="mb-1" hide-details outlined dense label="Notes" v-model="serialDialog.activeArea.notes"/>
                          </v-col>
                          <v-col>
                            <h3 class="mb-3 d-flex flex-row align-center">
                              <span>Delivery Address</span>
                              <v-btn :disabled="serialDialog.activeArea.status===-1 || serialDialog.activeArea.status===5" class="ml-2" x-small color=info fab @click="importAddressFromOrder"><v-icon>mdi-application-import</v-icon></v-btn>
                            </h3>
                            <v-text-field label="Address Line 1" outlined dense hide-details class="mb-1" v-model="serialDialog.activeArea.address.line1"/>
                            <v-text-field label="Address Line 2" outlined dense hide-details class="mb-1" v-model="serialDialog.activeArea.address.line2"/>
                            <v-text-field label="City" outlined dense hide-details class="mb-1" v-model="serialDialog.activeArea.address.city"/>
                            <v-text-field label="Country" outlined dense hide-details class="mb-1" v-model="serialDialog.activeArea.address.country"/>
                            <v-text-field label="Phone" outlined dense hide-details class="mb-1" v-model="serialDialog.activeArea.address.phone"/>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-form>
                  <v-card class="mt-2" outlined>
                    <v-card-text class="d-flex flex-column">
                      <span class="d-flex flex-row align-center">
                        <h2 class="mr-2">Items in this Dispatch</h2>
                        <v-btn color="info" small @click="fillQTYBalance" :loading="fillLoading">Fill in QTY Balance</v-btn>
                        <v-progress-circular
                            indeterminate
                            color="green"
                            v-if="serialDialog.isSyncing"
                            class="ml-2"
                        ></v-progress-circular>
                      </span>
                      <div class="mb-4 d-flex flex-column justify-space-between">
                        <span v-for="(item,index) in serialDialog.activeArea.items" :key="index" class="d-flex flex-column flex-grow-1">
                          <span class="d-flex flex-row align-center justify-space-between">
                            <span class="d-flex flex-row align-center">
                              <div class="mr-2" v-if="item.metadata?.quantities?.length <= 3">
                                <span class="d-flex flex-row align-center" v-for="(loc, index) in item.metadata.quantities" :key="index">
                                  <v-text-field :disabled="serialDialog.activeArea.status!==0" hide-details type="number" :step="utils.pff(item.metadata?.quantityStepAmount||1)" :min="utils.pff(item.metadata?.quantityStepAmount||1)" dense v-model="item.metadata.quantities[index].deliveryQuantity" @change="updateLineItem(item)" :label="lookupBranch(loc.locationId)||'Deleted Location'" :max="item.max" style="min-height: 10px; font-weight: bold; font-size: 12px; padding: 0px; padding-top: 5px; width: 100px;" outlined/>
                                  <span class="ml-2">Order: {{item.metadata.quantities[index].quantity||0}}</span>
                                </span>
                                <span>Total: {{utils.pff(item.quantity)}}</span>
                              </div>
                              <div class="mr-2" v-else-if="item.metadata?.quantities?.length > 3">
                              <span class=" mr-2 d-flex flex-row pb-2">
                                <v-text-field :disabled="serialDialog.activeArea.status!==0" v-for="(loc, index) in item.metadata.quantities" hide-details :key="index" type="number" :step="utils.pff(item.metadata?.quantityStepAmount||1)" :min="utils.pff(item.metadata?.quantityStepAmount||1)" dense v-model="item.metadata.quantities[index].deliveryQuantity" @change="updateLineItem(item)" :label="lookupBranch(loc.locationId)||'Deleted Location'" :max="item.max" style="min-height: 10px; font-weight: bold; font-size: 12px; padding: 0px; padding-top: 5px; width: 100px;" outlined/>
                              </span>
                              </div>
                              <div class="mr-2" v-else>
                                <v-text-field :disabled="serialDialog.activeArea.status!==0" label="Quantity" type="number" :step="utils.pff(item.metadata?.quantityStepAmount||1)" hide-details :min="utils.pff(item.metadata?.quantityStepAmount||1)" dense v-model="item.quantity" @change="updateLineItem(item)" :max="item.max" style="width: 100px;" outlined/>
                              </div>
                              <span class="ml-3 d-flex flex-column">
                                <h4>{{ computeProductName(item.productId) }}
                                  <router-link v-if="isAllowed('product', 'u')" :to="'/products/view/'+item.productId">[PL-{{item.productId}}]</router-link>
                                  <span v-else>[PL-{{item.productId}}]</span>
                                  <br>
                                  <span>Total from Sale Order: {{computeLineItem(item)?.quantity||0}}</span>
                                </h4>
                              </span>
                              <span class="ml-5">
                                <v-badge v-if="!computeLineItem(item)?.Product.metadata?.allowFractional && computeLineItem(item)?.Product.metadata?.requireSerials && (utils.pff(item.quantity)-item.serials?.length)!==0" offset-x="15" offset-y="15" overlap bottom color="error" :content="`${(utils.pff(item.quantity)-(item.serials?.length))!==0?utils.pff(item.quantity)-(item.serials?.length):''}`">
                                  <v-btn class="ml-1 mb-1" @click="openLineItemSerialsDialog(item)" x-small fab color="warning"><v-icon>mdi-barcode</v-icon></v-btn>
                                </v-badge>
                                <v-badge v-else-if="!computeLineItem(item)?.Product.metadata?.allowFractional && (utils.pff(item.quantity)-item.serials?.length)!==0" offset-x="15" offset-y="15" overlap :color="utils.pff(item.quantity)<item.serials?.length?'error':'success'" :content="`${(utils.pff(item.quantity)-(item.serials?.length))!==0?utils.pff(item.quantity)-(item.serials?.length):''}`">
                                  <v-btn class="ml-1 mb-1" @click="openLineItemSerialsDialog(item)" x-small fab color="warning"><v-icon>mdi-barcode</v-icon></v-btn>
                                </v-badge>
                                <v-btn color="success" v-else-if="!computeLineItem(item)?.Product.metadata?.allowFractional" class="ml-1 mb-1" @click="openLineItemSerialsDialog(item)" x-small fab><v-icon>mdi-barcode</v-icon></v-btn>
                                <span v-else-if="computeLineItem(item)?.Product.metadata?.allowFractional">Item does not support {{serialNaming.pluralLower}}.</span>
                              </span>
                            </span>
                            <span class="d-flex flex-row align-center justify-space-between">
                              <span class="d-flex flex-column">
                                <b>Total Balance: {{item.balance||0}}</b>
                                <span>Total Scheduled: {{item.scheduledQuantity||0}}</span>
                                <span>Total Out For Delivery: {{item.outForDeliveryQuantity||0}}</span>
                                <span>Total Delivered: {{item.deliveredQuantity||0}}</span>
                              </span>
                            </span>
                            <!-- <v-btn class="ml-2" color="success" x-small fab @click="addProductToDispatch"><v-icon>mdi-plus</v-icon> </v-btn> -->
                          </span>
                          <v-divider v-if="index < serialDialog.activeArea.items.length-1"></v-divider>
                        </span>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card outlined>
                    <v-card-text>
                        <div class="ml-4 d-flex flex-column" style="overflow-y: scroll; overflow-x: hidden; max-height: 800px;">
                          <h3 class="mb-3">Timeline</h3>
                          <v-timeline class="ml-n7" id="timeline" dense clipped>
                            <v-slide-x-reverse-transition
                                group
                                hide-on-leave
                            >
                              <v-timeline-item
                                  v-for="(item, index) in serialDialog.activeArea.history"
                                  :key="index"
                                  small
                                  fill-dot
                              >
                                <v-alert
                                    :value="true"
                                    class="white--text d-flex flex-column"
                                    color="info"
                                    dense
                                    small
                                >
                                  <div style="font-size: 14px;">{{item.description}}</div>
                                  <b style="font-size: 12px;">{{ lookupUsername(item.createdBy) }} at {{ utils.formatDate(item.createdAt, 'withTime') }}</b>
                                </v-alert>
                              </v-timeline-item>
                            </v-slide-x-reverse-transition>
                          </v-timeline>
                        </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="lineItemSerialsDialog.isOpen" width="1000">
      <v-card>
        <v-card-title v-if="lineItemSerialsDialog.data">
          {{serialNaming.plural}} for {{computeLineItem(lineItemSerialsDialog.data)?.productName}} (ID: {{lineItemSerialsDialog.data.productId}})
        </v-card-title>
        <v-card-text v-if="lineItemSerialsDialog.data && serialDialog.data && lineItemSerialsDialog.filtered">
          <v-row>
            <v-col cols="4" style="border-right: 1px solid #eee" class="d-flex flex-column">
              <span v-if="serialDialog.activeArea.status===0" class="d-flex flex-column">
                <h3>Add {{serialNaming.singular}}</h3>
                <span class="d-flex flex-row justify-space-between align-center">
                  <v-text-field clearable @keydown.enter="addLineItemSerial" :loading="lineItemSerialsDialog.isLoading" v-model="lineItemSerialsDialog.newSerial" outlined hide-details dense :label="`${serialNaming.singular}`"/>
                  <v-btn :disabled="(lineItemSerialsDialog.data.serials?.length>=utils.pff(lineItemSerialsDialog.data.quantity))||!lineItemSerialsDialog.newSerial" :loading="lineItemSerialsDialog.isLoading" @click="addLineItemSerial" class="ml-2" color="success" fab x-small><v-icon>mdi-plus</v-icon></v-btn>
                </span>
                <span v-if="lineItemSerialsDialog.data.Product?.metadata?.requireSerials" class="red--text">This product requires all {{serialNaming.pluralLower}} to be entered.</span>
                <span v-if="lineItemSerialsDialog.data.Product?.metadata?.requireSerials && getGlobalValue('replaceProductSerialsWithIMEI')">All {{serialNaming.pluralLower}} must be unique from all other products and itself.</span>
                <span v-if="!lineItemSerialsDialog.data.Product?.metadata?.requireSerials && getGlobalValue('replaceProductSerialsWithIMEI')">This product does not have {{serialNaming.pluralLower}} required but must still be unique if they're included.</span>
                <hr class="my-3">
              </span>
              <span>
                <h2>{{serialNaming.plural}} From Sale Order / Already Dispatched</h2>
                <span v-if="serialDialog.data.ProductSerials.length>0">
                  <span v-if="serialDialog.activeArea.status===0">You can only add these by typing it in or scanning it.</span>
                  <div class="mt-2" v-if="getGlobalValue('VEC_ALLOW_WAREHOUSE_INVENTORY_PULLING')==='true'">
                    <span v-for="(warehouse, w) of warehouses" :key="w">
                      <h3>{{lookupBranch(warehouse.id)}}</h3>
                      <span v-if="serialDialog.data.ProductSerials.filter(x => x.locationId===warehouse.id).length>0">
                        <v-card class="mb-2" outlined v-for="(serial, i) of serialDialog.data.ProductSerials.filter(x => x.locationId===warehouse.id)" :key="i">
                          <span class="pa-2 pl-5 d-flex flex-row align-center justify-space-between">
                            <b>{{serial.serial}}</b>
                            <span class="d-flex flex-row">
                              <v-btn v-if="isAllowed('dispatch', 'removeSerialFromOrder') && serialDialog.activeArea.status===0" :disabled="lineItemSerialsDialog.isLoading" @click="openRemoveOrderLineItemSerialDialog(serial)" color="error" fab x-small><v-icon>mdi-close</v-icon></v-btn>
                            </span>
                          </span>
                        </v-card>
                      </span>
                      <span v-else>
                        <span>No matching {{serialNaming.pluralLower}} entered for this location.</span>
                      </span>
                      <hr class="mb-2">
                    </span>
                  </div>
                  <div class="mt-2" v-else>
                    <v-card class="mb-2" outlined v-for="(serial, i) of lineItemSerialsDialog.data.ProductSerials" :key="i">
                      <span class="pa-2 pl-5 d-flex flex-row align-center justify-space-between">
                        <b>{{serial.serial}}</b>
                        <span class="d-flex flex-row">
                          <v-btn v-if="isAllowed('dispatch', 'removeSerialFromOrder') && serialDialog.activeArea.status===0" :disabled="lineItemSerialsDialog.isLoading" @click="openRemoveOrderLineItemSerialDialog(serial)" color="error" fab x-small><v-icon>mdi-close</v-icon></v-btn>
                        </span>
                      </span>
                    </v-card>
                  </div>
                </span>
                <span v-else>
                  No {{serialNaming.pluralLower}} in this sale order.
                </span>
              </span>
            </v-col>
            <v-col>
              <h3>{{serialNaming.plural}} ({{lineItemSerialsDialog.data.serials?.length||0}}/{{utils.pff(lineItemSerialsDialog.data.quantity)}})</h3>
              <v-text-field :disabled="lineItemSerialsDialog.data.serials.length===0" :hint="lineItemSerialsDialog.data.serials.length===0?'No items in list.':''" :persistent-hint="lineItemSerialsDialog.data.serials.length===0" dense outlined label="Search List" v-model="lineItemSerialsDialog.search" clearable @input="filterLineItemSerials"/>
              <span v-if="getGlobalValue('VEC_ALLOW_WAREHOUSE_INVENTORY_PULLING')==='true'" class="d-flex flex-column" style="max-height: 600px; overflow-y: auto;">
                <span v-for="(warehouse, w) of warehouses" :key="w">
                  <h3>{{lookupBranch(warehouse.id)}}</h3>
                  <span v-if="lineItemSerialsDialog.filtered.filter(x => x.locationId===warehouse.id).length>0">
                    <v-card class="mb-2" outlined v-for="(serial, i) of lineItemSerialsDialog.filtered.filter(x => x.locationId===warehouse.id)" :key="i">
                      <span class="pa-2 pl-5 d-flex flex-row align-center justify-space-between">
                        <b>{{serial.serial}} (ID: {{serial.id}})</b>
                        <span class="d-flex flex-row">
                          <v-btn v-if="serialDialog.activeArea.status===0" :disabled="lineItemSerialsDialog.isLoading" @click="removeLineItemSerial(serial.id)" color="error" fab x-small><v-icon>mdi-close</v-icon></v-btn>
                        </span>
                      </span>
                    </v-card>
                  </span>
                  <span v-else>
                    <span>No matching serials entered for this location.</span>
                  </span>
                  <hr class="mb-2">
                </span>
              </span>
              <span v-else class="d-flex flex-column" style="max-height: 600px; overflow-y: auto;">
              <v-card class="mb-2" outlined v-for="(serial, i) of lineItemSerialsDialog.filtered" :key="i">
                <span class="pa-2 pl-5 d-flex flex-row align-center justify-space-between">
                  <b>{{serial.serial}}</b>
                  <span class="d-flex flex-row">
                    <v-btn v-if="serialDialog.activeArea.status===0" :disabled="lineItemSerialsDialog.isLoading" @click="removeLineItemSerial(serial.id)" color="error" fab x-small><v-icon>mdi-close</v-icon></v-btn>
                    <!-- <v-btn v-if="serialDialog.activeArea.status && serialDialog.activeArea.status>=1" class="ml-2" @click="$router.push({path: '/products/serial/'+serial.serial})" color="success" fab x-small><v-icon>mdi-history</v-icon></v-btn> -->
                  </span>
                  <!-- <v-btn v-if="!serialDialog.activeArea.status || serialDialog.activeArea.status<1" :disabled="lineItemSerialsDialog.isLoading" @click="removeLineItemSerial(serial.id)" color="error" fab x-small><v-icon>mdi-close</v-icon></v-btn> -->
                </span>
              </v-card>
            </span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeLineItemSerialsDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="removeOrderLineItemSerialDialog.isOpen" width="400">
      <v-card>
        <v-card-title>Remove {{serialNaming.singular}} from Sale Order?</v-card-title>
        <v-card-text v-if="removeOrderLineItemSerialDialog.data">
          Are you sure you want to remove {{serialNaming.singular}} - {{removeOrderLineItemSerialDialog.data.serial}}, from the Sale Order?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeRemoveOrderLineItemSerialDialog">Cancel</v-btn>
          <v-btn color="error" @click="removeOrderLineItemSerial">Remove {{serialNaming.singular}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<style>
#existingSerials{
  border-collapse: collapse;
}
#existingSerials td, #existingSerials th{
  border: 1px solid #ff8080;
  padding: 5px;
}
#existingSerials tbody tr:hover{
  background: #f50000;
  color: white;
}
</style>
<script>
  import axios from 'axios';
  import utils from "../../plugins/helpers";
  import {mapGetters} from 'vuex'
  import confirmedActionButton from './../../components/confirmedActionButton.vue';
  import dynamicButtonContainer from './../../components/dynamicButtonContainer.vue';
  export default {
    components: {
      confirmedActionButton,
      dynamicButtonContainer
    },
    data () {
      return {
        utils: utils,
        loader: false,
        noteLoading: false,
        snackObj: {
          state: false,
          color: '',
          text: ''
        },
        createDispatchLoading: false,
        serialDialog: {
          activeArea: {address: {}},
          isOpen: false,
          isLoading: false,
          isSyncing: false,
          isConfirming: false,

          selected: null,
          data: null,
          existing: null,
          headers: [
            {
              text: 'QTY',
              align: 'start',
              sortable: false,
              value: 'quantity',
            },
            {
              text: 'Product #',
              align: 'start',
              sortable: false,
              value: 'productId',
            },
            { text: 'Name', value: 'productName', sortable: false },
            { text: 'Serials', value: 'serials', sortable: false }
          ]
        },

        warehouses: [],

        singular: "Dispatch",
        singularLower: "dispatch",
        plural: "Dispatches",
        pluralLower: "dispatches",

        statuses: [
          {text: "All", value: null},
          {text: "New", value: 0},
          {text: "In Progress", value: 1},
          {text: "Completed", value: 2},
          {text: "Voided", value: -1},
        ],

        //some includes itself because it's needed to show up in the v-select
        dispatchStatuses: [
          {text: "Voided", value: -1, allowedNext: [-1]},
          {text: "New", value: 0, allowedNext: [-1, 0, 1]},
          {text: "Scheduled", value: 1, allowedNext: [-1, 0, 1, 2, 3, 5]},
          {text: "Out for Delivery", value: 2, allowedNext: [-1, 1, 2, 3, 5]},
          {text: "Attempted Delivery", value: 3, allowedNext: [-1, 1, 2, 3, 5]},
          {text: "Delayed", value: 4, allowedNext: [-1, 1, 2, 3, 4, 5]},
          {text: "Delivered", value: 5, allowedNext: [5]},
          {text: "Other", value: -2, allowedNext: [-2, -1, 0, 1, 2, 3, 5]},
        ],
        tempStatus: 0,

        lineErrors: {
          productId: null,
          errors: []
        },

        lineItemSerialsDialog: {
          isOpen: false,
          data: null,
          isLoading: false
        },

        removeOrderLineItemSerialDialog: {
          isOpen: false,
          data: null,
          isLoading: false
        },

        fillLoading: false,
        today: null,
      }
    },
    created(){
      let i = this.serialDialog.headers.findIndex(x => x.value==='serials');

      if(this.getGlobalValue('replaceProductSerialsWithIMEI')){
        if(i>=0){
          this.serialDialog.headers[i].text = this.serialNaming.plural
        }
      }
      if(!this.getGlobalValue('productSerialShowOnDispatch')){
        i = this.serialDialog.headers.findIndex(x => x.value==='serials');
        if(i>=0){
          this.serialDialog.headers.splice(i, 1);
        }
      }

      if(this.getGlobalValue('hiddenDispatchStatusesFromFrontend')){
        let statuses = this.getGlobalValue('hiddenDispatchStatusesFromFrontend').split(",").map(x => x.trim())

        for(let status of statuses){
          let sI = this.dispatchStatuses.findIndex(x => x.text===status);
          if(!sI<0) continue;

          let s = this.dispatchStatuses[sI];

          for(let ds of this.dispatchStatuses){
            if(ds.text===s.text) continue;
            ds.allowedNext = ds.allowedNext.filter(x => x!==s.value);
          }

          this.dispatchStatuses.splice(sI, 1);
        }
      }
    },
    async mounted(){
      await this.openSerialDialog(this.$route.params.oid, this.$route?.query.curr||null);

      if(this.getGlobalValue("VEC_ALLOW_WAREHOUSE_INVENTORY_PULLING")==='true'){
        let warehouses = await axios.get(`${this.getEndpoint}/api/locations/warehouses`);
        if(warehouses.data.error) throw warehouses.data.error

        this.warehouses = warehouses.data.data;
        this.warehouses = this.warehouses.filter(x => x.id!==this.getBranch)
        this.warehouses = [{id: this.getBranch}].concat(this.warehouses);
      }

      let today = new Date();
      const offset = today.getTimezoneOffset()
      today = new Date(today.getTime() - (offset*60*1000))
      today = today.toISOString().split('T')[0];
      this.today = today;

      this.scrollTimelineToBottom();
    },
    computed: {
      changeDispatchStatusRules(){
        if(!this.serialDialog.data.id) return true
        else if(!this.serialDialog.activeArea || this.serialDialog.activeArea == {} || Object.keys(this.serialDialog.activeArea).length == 0) return true
        else if(this.serialDialog.activeArea.status == -1) return true
        else if(this.serialDialog.activeArea.status == 5) return true
        else return false
      },
      voidDispatchRules(){
        if(!this.serialDialog.data.id) return true
        else if(!this.serialDialog.activeArea || this.serialDialog.activeArea == {} || Object.keys(this.serialDialog.activeArea).length == 0) return true
        else if(this.serialDialog.activeArea.status == -1) return true
        else if(this.serialDialog.activeArea.status == 5) return true
        else return false
      },
      canPrint(){
        if(!this.serialDialog.data) return false;
        if(this.serialDialog.isLoading) return false;
        if(this.serialDialog.isSyncing) return false;
        // for(let oli of this.serialDialog.data.OrderLineItems){
        //   if(!oli.Product.metadata?.requireSerials) continue;
        //   if(!oli.Product.ProductSerials) return false;
        //   if(oli.Product.ProductSerials.length===0) return false;
        //   if(oli.Product.ProductSerials.length!==oli.quantity) return false;
        // }

        for(let oli of this.serialDialog.data.OrderLineItems){
          if(oli.Product?.metadata?.requireSerials){
            if(utils.pff(oli.quantity)!=(oli.serials?.length||0)) return false;
          }
          if(utils.pff(oli.quantity)<(oli.serials?.length||0)) return false
        }

        return true
      },
      ...mapGetters(['getId', 'getEndpoint', 'lookupUsername', 'getGlobalValue', 'lookupBranch', 'getBranch', 'getBranches', 'isAllowed', 'getUser', 'serialNaming']),
    },
    methods: {
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      computeProductName(id){
        let name = this.serialDialog.data.OrderLineItems.find(x=>x.productId === id)
        return name.productName
      },
      computeLineItem(item){
        let oli = this.serialDialog.data.OrderLineItems.find(x => x.productId===item.productId);

        return oli;
      },
      importAddressFromOrder(){
        this.serialDialog.activeArea.address = {...this.serialDialog.data.deliveryInfo.address}
        this.$forceUpdate()
      },
      async markCompleted(){
        try {
          let res = await axios.post(`${this.getEndpoint}/api/dispatch/markCompleted/${this.serialDialog.data.id}/${this.serialDialog.activeArea.id}`)
          if(res.data.error) throw res.data.error
          this.snack("Dispatch Marked as Collected.")
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
      },
      async markSentOut(){
        try {
          let res = await axios.post(`${this.getEndpoint}/api/dispatch/markSentOut/${this.serialDialog.data.id}/${this.serialDialog.activeArea.id}`)
          if(res.data.error) throw res.data.error
          this.snack("Dispatch Marked as Sent Out.")
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
      },
      updateLineTotals(){
        for(let item of this.serialDialog.activeArea.items){
          item.scheduledQuantity = 0
          item.outForDeliveryQuantity = 0
          item.deliveredQuantity = 0

          for(let dispatch of this.serialDialog.data.deliveryInfo.dispatches){
            if(dispatch.status===-1) continue;

            let pli = dispatch.items.find(x => x.productId===item.productId);
            if(!pli) continue;

            if(dispatch.status===1) item.scheduledQuantity+=pli.quantity;
            if(dispatch.status===2) item.outForDeliveryQuantity+=pli.quantity;
            if(dispatch.status===5) item.deliveredQuantity+=pli.quantity;
          }

          item.balance = (this.computeLineItem(item)?.quantity || 0)-item.scheduledQuantity-item.outForDeliveryQuantity-item.deliveredQuantity;
        }

        this.$forceUpdate();
      },
      async saveDispatch(id){
        try {
          let obj = {...this.serialDialog.activeArea}
          obj.status = this.tempStatus;

          let res = await axios.post(`${this.getEndpoint}/api/dispatch/save/${this.serialDialog.data.id}/${id}`, {data: obj})
          if(res.data.error) throw res.data.error

          await this.openSerialDialog(this.$route.params.oid, id)
          console.log(this.serialDialog.activeArea)

          this.$forceUpdate();

          this.snack("Dispatch data saved.")
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
      },
      async createNewDispatch(id){
        try {
          this.createDispatchLoading = true

          let res = await axios.post(`${this.getEndpoint}/api/dispatch/${id}`)
          if(res.data.error) throw res.data.error

          if(!this.serialDialog.data.deliveryInfo.dispatches){
            this.serialDialog.data.deliveryInfo.dispatches = []
          }
          this.serialDialog.data.deliveryInfo.dispatches.push(res.data.data)
          this.selectActiveDispatch(this.serialDialog.data.deliveryInfo.dispatches.length-1)

          this.snack("New Dispatch Created.")
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.createDispatchLoading = false
        }
      },
      async clickDispatch(dispatchId){
        await this.openSerialDialog(this.$route.params.oid, dispatchId)
        await this.$router.replace({path: `/dispatch/view/${this.$route.params.oid}`, query: {curr: dispatchId}});
      },
      selectActiveDispatch(index){
        this.serialDialog.activeArea = this.serialDialog.data.deliveryInfo.dispatches[index]
        this.tempStatus = this.serialDialog.activeArea.status;

        if(!this.serialDialog.activeArea.address){
          this.serialDialog.activeArea.address = {}
        }
        this.serialDialog.activeArea.ix = index

        this.scrollTimelineToBottom();

        this.updateLineTotals();
      },
      async openSerialDialog(id, dispatchId=null){
        try{
          this.serialDialog.isSyncing = true;

          await this.getOrderData(id)

          this.serialDialog.isOpen = true;
          this.serialDialog.activeArea = {address: {}}
          if(this.serialDialog.data.deliveryInfo.dispatches && this.serialDialog.data.deliveryInfo.dispatches.length > 0){
            for(let dispatch of this.serialDialog.data.deliveryInfo.dispatches){
              let statusCategory = this.dispatchStatuses.find(x => x.value===dispatch.status);
              if(!statusCategory){
                dispatch.status = -2
              }
            }

            let dix = this.serialDialog.data.deliveryInfo.dispatches.findIndex(x => x.id===dispatchId);
            this.selectActiveDispatch(dix>=0?dix:0)
          }
        }
        catch (error) {
          console.error(error);
          this.snack(error.msg || error.msg?.message || error, "error");
        }
        finally {
          this.serialDialog.isSyncing = false;
        }
      },
      closeSerialDialog(keepExisting=false){
        this.serialDialog.isOpen = false;
        this.serialDialog.selected = null;
        this.serialDialog.data = null;
        this.serialDialog.activeArea = {}
        if(!keepExisting) this.serialDialog.existing = null;
      },
      async getOrderData(id){
        let res = await axios.get(`${this.getEndpoint}/api/dispatch/serials/${id}`)
        if(res.data.error) throw res.data.error
        this.serialDialog.data = res.data.data
      },
      async openLineItemSerialsDialog(item){
        try{
          this.lineItemSerialsDialog.isLoading = true;
          this.lineItemSerialsDialog.data = item;

          await this.getLineSerials();

          this.lineItemSerialsDialog.filtered = item.serials?[...item.serials]:[]

          this.lineItemSerialsDialog.isOpen = true;
          this.lineItemSerialsDialog.other = null;
          this.lineItemSerialsDialog.search = "";
          this.lineItemSerialsDialog.newSerial = ""
        }
        catch (error) {
          console.error(error);
          this.snack(error.msg || error.msg?.message || error, "error");
        }
        finally {
          this.lineItemSerialsDialog.isLoading = false;
        }
      },
      closeLineItemSerialsDialog(){
        this.lineItemSerialsDialog.isOpen = false;
        this.lineItemSerialsDialog.data = null;
        this.lineItemSerialsDialog.other = null;
        this.lineItemSerialsDialog.search = "";
        this.lineItemSerialsDialog.newSerial = ""
        this.lineItemSerialsDialog.filtered = []
      },
      async getLineSerials(){
        if(!this.lineItemSerialsDialog.data) return;

        let res = await axios.post(`${this.getEndpoint}/api/${this.singularLower}/${this.serialDialog.data.id}/getDispatchLineSerials`, {dispatchId: this.serialDialog.activeArea.id, productId: this.lineItemSerialsDialog.data.productId})
        if(res.data.error) throw res.data.error

        this.lineItemSerialsDialog.data.serials = res.data.data

        this.filterLineItemSerials();

        this.updateLineTotals();
      },
      scrollTimelineToBottom(){
        let timelineDiv = document.getElementById("timeline");
        if(!timelineDiv) return;

        timelineDiv.scrollTop = timelineDiv.scrollHeight;
      },
      async addLineItemSerial(){
        try{
          this.lineItemSerialsDialog.isLoading = true;

          let item = this.lineItemSerialsDialog.data

          if(!this.lineItemSerialsDialog.newSerial?.trim()){
            throw `Serial cannot be empty.`
          }

          this.lineItemSerialsDialog.newSerial.replace("\\//\\=", "")

          if(item.serials?.length>=utils.pff(item.quantity)){
            throw `Max number of ${this.serialNaming.pluralLower} for this line item.`
          }

          let res = await axios.post(`${this.getEndpoint}/api/${this.singularLower}/${this.serialDialog.data.id}/dispatchSerials`, {dispatchId: this.serialDialog.activeArea.id, productId: item.productId, serial: this.lineItemSerialsDialog.newSerial.trim()})
          if(res.data.error) throw res.data.error

          let lix = this.serialDialog.activeArea.items.findIndex(x=>x.productId === item.productId)
          this.lineItemSerialsDialog.data = res.data.data.item

          this.serialDialog.activeArea.items.splice(lix,1,res.data.data.item)
          this.serialDialog.activeArea.history = res.data.data.history

          this.scrollTimelineToBottom();

          await this.getLineSerials();

          this.$forceUpdate()

          this.lineItemSerialsDialog.newSerial = ""
        }
        catch (error) {
          console.error(error);
          this.snack(error.msg || error.msg?.message || error, "error");
        }
        finally {
          this.lineItemSerialsDialog.isLoading = false;
        }
      },
      async removeLineItemSerial(serial){
        try{
          this.lineItemSerialsDialog.isLoading = true;

          let item = this.lineItemSerialsDialog.data

          let res = await axios.put(`${this.getEndpoint}/api/${this.singularLower}/${this.serialDialog.data.id}/dispatchSerials`, {dispatchId: this.serialDialog.activeArea.id, productId: item.productId, serialId: serial})
          if(res.data.error) throw res.data.error

          let lix = this.serialDialog.activeArea.items.findIndex(x=>x.productId === item.productId)
          this.lineItemSerialsDialog.data = res.data.data.item
          this.serialDialog.activeArea.items.splice(lix,1,res.data.data.item)

          this.serialDialog.activeArea.history = res.data.data.history

          this.scrollTimelineToBottom();

          await this.getLineSerials();

          this.$forceUpdate()
        }
        catch (error) {
          console.error(error);
          this.snack(error.msg || error.msg?.message || error, "error");
        }
        finally {
          this.lineItemSerialsDialog.isLoading = false;
        }
      },
      openRemoveOrderLineItemSerialDialog(item){
        this.removeOrderLineItemSerialDialog.data = item;
        this.removeOrderLineItemSerialDialog.isOpen = true;
      },
      closeRemoveOrderLineItemSerialDialog(){
        this.removeOrderLineItemSerialDialog.isOpen = false;
        this.removeOrderLineItemSerialDialog.data = null;
      },
      async removeOrderLineItemSerial(){
        try{
          this.removeOrderLineItemSerialDialog.isLoading = true;

          let id = this.removeOrderLineItemSerialDialog.data.id;

          let res = await axios.put(`${this.getEndpoint}/api/${this.singularLower}/${this.serialDialog.data.id}/removeSerialFromOrder`, {serialId: id})
          if(res.data.error) throw res.data.error

          let lix = this.serialDialog.data.ProductSerials.findIndex(x=>x.id === id)
          this.serialDialog.data.ProductSerials.splice(lix,1)

          this.closeRemoveOrderLineItemSerialDialog();

          this.$forceUpdate()
        }
        catch (error) {
          console.error(error);
          this.snack(error.msg || error.msg?.message || error, "error");
        }
        finally {
          this.removeOrderLineItemSerialDialog.isLoading = false;
        }
      },
      filterLineItemSerials(){
        if(!this.lineItemSerialsDialog.data) return;

        if(!this.lineItemSerialsDialog.search){
          this.lineItemSerialsDialog.filtered = [...this.lineItemSerialsDialog.data.serials]
          return
        }

        this.lineItemSerialsDialog.filtered = this.lineItemSerialsDialog.data.serials.filter(x => x.toLowerCase().includes(this.lineItemSerialsDialog.search.toLowerCase()))
      },
      async updateLineItem(item){
        try {
          this.serialDialog.isSyncing = true;

          let tempItem = {...item};

          if(tempItem.metadata.quantities?.length>0){
            for(let loc of tempItem.metadata.quantities){
              if(!loc.deliveryQuantity) loc.deliveryQuantity = 0;
              else loc.deliveryQuantity = utils.pff(loc.deliveryQuantity);
            }
            tempItem.quantity = utils.pff(tempItem.metadata.quantities.reduce((a, c) => a+utils.pff(c.deliveryQuantity), 0));
          }

          else if(!tempItem.quantity) tempItem.quantity = utils.pff(0)

          tempItem.quantity = utils.pff(tempItem.quantity)

          let res = await axios.put(`${this.getEndpoint}/api/dispatch/updateLineItem/${this.serialDialog.data.id}`, {item: tempItem, dispatch: this.serialDialog.activeArea.id})
          if(res.data.error) throw res.data.error

          let ix = this.serialDialog.activeArea.items.findIndex(x=>x.productId===item.productId)
          if(ix>=0) {
            this.serialDialog.activeArea.items[ix] = res.data.data;
            item.quantity = res.data.data.quantity;
          }

          this.$forceUpdate()
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.serialDialog.isSyncing = false;
        }
      },
      async fillQTYBalance(){
        try{
          this.fillLoading = true;

          for(let item of this.serialDialog.activeArea.items){
            item.quantity = item.balance || this.computeLineItem(item)?.quantity || 0
            await this.updateLineItem(item)
          }
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.fillLoading = false;
        }
      }
    }
  }
</script>
